import React from 'react';
import { Nav } from '../components/Nav';

const channelShortName = {
  dev: 'dev-',
  'release-candidate': 'rc-',
  release: ''
};

const windowsInstallerURL = (channel) =>
  'https://storage.googleapis.com/embark-ark-public/portal/signed-installer/x86_64-pc-windows-msvc/' +
  channel +
  '/wim-' +
  channelShortName[channel] +
  'setup.exe';
const macOSInstallerURL = (channel) =>
  'https://storage.googleapis.com/embark-ark-public/portal/signed-installer/aarch64-apple-darwin/' +
  channel +
  '/wim.pkg';
const linuxInstallerURL = (channel) =>
  'https://storage.googleapis.com/embark-ark-public/portal/installer-script/x86_64-unknown-linux-gnu/' +
  channel +
  '/ark-installer.sh';
const androidInstallerURL = (channel) =>
  'https://storage.googleapis.com/embark-ark-public/releases/aarch64-linux-android/release-with-debuginfo/' +
  channel +
  '/ark-client-android.apk';

const releaseInfo = {
  dev: 'Latest changes on main branch, could break at any time',
  'release-candidate': 'Release candidates, used for testing before a release',
  release: 'Stable release used for playtests etc'
};

/* const installerBaseUrls = {
  mac:
    'https://storage.googleapis.com/embark-ark-public/portal/signed-installer/aarch64-apple-darwin/',
  windows:
    'https://storage.googleapis.com/embark-ark-public/portal/signed-installer/x86_64-pc-windows-msvc/',
  linux:
    'https://storage.googleapis.com/embark-ark-public/portal/installer-script/x86_64-unknown-linux-gnu/'
};
 */
const cacheBustUrl = (url) =>
  url + '?v=' + (+new Date()).toString(36).slice(-5);

export const Install = () => {
  return (
    <main className="container gradient-link-page">
      <Nav />

      <div className="home">
        <h1>Install wim</h1>
        <h2 className="install-subtitle">
          To get started, you'll need the wim client installed.
        </h2>
      </div>

      <section>
        {/* <h2 className="links-header">Installations</h2>

        <h3>Windows 🪟</h3>
        <div className="install-links flex-row">
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['windows'] + 'dev/wim-dev-setup.exe'
            )}>
            <h3>🚧</h3>
            <h4>Windows [dev]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['windows'] +
                'release-candidate/wim-rc-setup.exe'
            )}>
            <h3>🐉</h3>
            <h4>Windows [rc]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['windows'] + 'release/wim-setup.exe'
            )}>
            <h3>🔮</h3>
            <h4>Windows [release]</h4>
          </a>
        </div>

        <h3>Mac 🍎</h3>
        <div className="install-links flex-row">
          <a
            className="btn"
            href={cacheBustUrl(installerBaseUrls['mac'] + 'dev/wim.pkg')}>
            <h3>🚧</h3>
            <h4>Mac [dev]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['mac'] + 'release-candidate/wim.pkg'
            )}>
            <h3>🐉</h3>
            <h4>Mac [rc]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(installerBaseUrls['mac'] + 'release/wim.pkg')}>
            <h3>🔮</h3>
            <h4>Mac [release]</h4>
          </a>
        </div>

        <h3>Linux 🐧</h3>
        <div className="install-links flex-row">
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['linux'] + 'dev/ark-installer.sh'
            )}>
            <h3>🚧</h3>
            <h4>Linux [dev]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['linux'] + 'release-candidate/ark-installer.sh'
            )}>
            <h3>🐉</h3>
            <h4>Linux [rc]</h4>
          </a>
          <a
            className="btn"
            href={cacheBustUrl(
              installerBaseUrls['linux'] + 'release/ark-installer.sh'
            )}>
            <h3>🔮</h3>
            <h4>Linux [release]</h4>
          </a>
        </div> */}

        {/* old installations*/}
        <h2 className="links-header">Installations</h2>

        {['dev', 'release-candidate', 'release'].map((channel) => (
          <>
            <h2 className="links-header">{channel} channel</h2>
            <h3>{releaseInfo[channel]}</h3>

            <div className="install-links flex-col">
              <a
                className="btn"
                href={cacheBustUrl(windowsInstallerURL(channel))}>
                <h3>💾</h3>
                <h4>Windows</h4>
              </a>

              <a
                className="btn"
                href={cacheBustUrl(macOSInstallerURL(channel))}>
                <h3>🍎</h3>
                <h4>macOS</h4>
              </a>

              <div className="linux-download">
                <h3>🐧</h3>
                <h4>Linux</h4>
                <p>Run the following command in a terminal:</p>
                <div className="linux-download-script">
                  curl --proto '=https' --tlsv1.2 -sSfL&nbsp;
                  {linuxInstallerURL(channel)} | sh
                </div>
              </div>

              {/* Disable until we can figure out how to get per channel download links
               <a
                className="btn"
                href={cacheBustUrl(androidInstallerURL(channel))}>
                <h3>☎️</h3>
                <h4>Android</h4>
                <p>Click to download APK</p>
              </a> */}
            </div>
          </>
        ))}
      </section>
    </main>
  );
};
